import React, { useEffect } from "react";
import { FiUnlock } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import LogoLogin from "../../assets/Logos/GroupLogo.svg";

// Styles from Login
import {
  Content,
  ContainerLogin,
  ContentLogo,
  Warning,
} from "./styles";

//Components form application
import Button from "../../components/Forms/Button";
import Input from "../../components/Forms/Input";
import { toast } from "../../utils/toast";
import Api from "../../services/apiService";

export default function ResetPassword() {
  const schema = yup.object().shape({
    firstPassword: yup.string().required("Campo obrigatório."),
    secondPassword: yup
      .string()
      .required("Campo obrigatório.")
      .test("passwords-match", "As senhas não coincidem", function (value) {
        return this.parent.firstPassword === value;
      }),
  });

  const navigate = useNavigate();
  const params = useParams();
  const defaultValues = {
    firstPassword: '',
    secondPassword: '',
  };
  async function onSubmit(payload) {
    try {
      const { data } = await Api.put(
        "/resetar-senha",
        { senha: payload.firstPassword }
      );
      toast("info", "Sucesso", data?.message || "Senha alterada com sucesso!");
      navigate("/");
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível alterar sua senha"
      );
    }
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let valid = params.token.split(".");
    if (valid.length !== 3) {
      navigate("/");
    }
  }, []);

  return (
    <Content>
      <ContainerLogin>
        <ContentLogo src={LogoLogin} />
        <Warning>
          Olá, Para continuar o processo de redefinição de senha, preencha os
          campos abaixo com a nova senha.
        </Warning>
        <Controller
          control={control}
          name="firstPassword"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FiUnlock />}
              TextInput="Digite uma nova senha"
              typeInput={"password"}
              handleChange={onChange}
              value={value}
              errorMessage={errors?.firstPassword?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="secondPassword"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FiUnlock />}
              TextInput="Confirme sua nova senha:"
              typeInput={"password"}
              handleChange={onChange}
              value={value}
              errorMessage={errors?.secondPassword?.message}
            />
          )}
        />
        <Button text="Resetar" HandleClick={handleSubmit(onSubmit)} />
      </ContainerLogin>
    </Content>
  );
}
