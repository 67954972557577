import * as yup from "yup";

const validateUrl = yup
.string().test({
    name: 'trimCheck',
    test(value) {
      return value === value.trim();
    },
    message: 'A URL não deve ter espaços em branco',
})
.required();

const getBaseUrl = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    try {
        validateUrl.validate(BASE_URL);
        return BASE_URL;
    } catch (error) {
        console.error(error.message);
        throw new Error('A validação da URL falhou.');
    }};
export default getBaseUrl