import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 0.8125rem;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 22px;
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.secondary};
  border-style: solid;
  border-radius: 8px;
  padding: 5px;
  align-items: center;
`;

export const SelectLabel = styled.span`
  margin-bottom: 5px;
`;

export const SelectContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const Select = styled.select`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border: none;
  transition: 0.3s;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: inherit;
  cursor: pointer;
  color: #0b0b0b;
  flex: 1;
  border-radius: 8px;
  padding: 0.28rem 5px;
`;

export const Pages = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 12pt;
`;

export const Icon = styled.p`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  justify-content: center;
  margin: 5px;
  color: ${({ disabled, theme }) =>
    disabled ? "rgba(2, 128, 248, .6)" : theme.colors.primary};
`;
