import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${props => props.isDisabled ? props.theme.colors.button : props.theme.colors.primary};
  align-items: center;
  transition: 0.3s opacity;
  box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.03);

  :hover{
    opacity: 0.8;
  }
`;

export const ButtonAttendance = styled.button`
  flex: 1;
  border: none;
  display: flex;
  flex-direction: column;
  text-align: start;
  background-color: inherit;

  :hover {
    background-color: inherit;
  }
`;

export const ButtonTitle = styled.span`
  font-size: 32px;
  color: ${({ isDisabled, theme }) => isDisabled ? theme.colors.textGray: theme.colors.white};
  font-family: 'Titillium Web';
`;

export const ButtonDescribe = styled.span`
  font-size: 20px;
  color: ${({ isDisabled, theme }) => isDisabled ? theme.colors.textGray: theme.colors.white};
`;