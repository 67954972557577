import { createStore, combineReducers } from 'redux';

import loadingReducer from './Reducers/loadingReducer';
import cronometerReducer from './Reducers/cronometerReducer';
import panelReducer from './Reducers/panelReducer'
import userReducer from './Reducers/userReducer';
import unitReducer from './Reducers/unitReducer';
import clinicReducer from './Reducers/clinicReducer';

const reducers = combineReducers({
  loading: loadingReducer,
  panel: panelReducer,
  user: userReducer,
  unit: unitReducer,
  cronometer: cronometerReducer,
  clinic: clinicReducer
})

const storeConfig = createStore(reducers);

export default storeConfig