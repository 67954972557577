import { GoTriangleUp, GoTriangleDown, GoDash } from 'react-icons/go';
import { Row, Col } from './styles';

export function ListHeader({itens = [], handleOrder}) {
  return (
    <Row>
      {itens.flatMap((item, index) => (
        <Col
          key={index.toString()}
          onClick={() => item.canOrder ? handleOrder(item.order) : undefined}
          canOrder={item.canOrder}
          large={item.large}
          isAction={item.isAction}
          isBroken={item.isBroken}>
          {item.label}
            {
              item.canOrder
              ? (
                <span style={{marginLeft: 10}}> {
                  item.isOrdered !== undefined && !item.isOrdered
                  ? <GoDash size={10} />
                  : (
                    item.asc
                    ? <GoTriangleUp size={10} />
                    : <GoTriangleDown size={10} />
                  )
                }
                </span>
              )
              : null
            }
        </Col>
      ))}
    </Row>
  );
}