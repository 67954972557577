import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";

import Aside from "../Aside";
import Header from "../Header";

import { Content, Container } from "./styles";

export default function Layout({
  token,
  handleProfile = () => undefined,
  hideHeader = false,
  hideProfile = false,
  hideAside = false,
  attendance = false,
  showAllAttendance = false,
  children,
}) {
  const navigate = useNavigate();
  const cookie = Cookie.get("token");

  useEffect(() => {
    if (!Cookie.get("token")) {
      navigate("/");
    }
  }, [cookie, navigate]);

  return (
    <Content>
      {hideHeader ? null
        : <Header hideProfile={hideProfile} handleProfile={handleProfile} />}
      <Container>
        {hideAside
        ? null : (
          <Aside
            admin={token}
            showAttendance={attendance}
            showAllAttendance={showAllAttendance}
          />
        )}
        {children}
      </Container>
    </Content>
  );
}
