import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Title = styled.span`
  font-size: 18px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

export const FilterButton = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 15px;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-top: 3px;
  transition: 0.2s opacity;

  :hover {
    opacity: 0.8;
  }
`;

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  margin: 0 15px;
`;

export const Spinner = styled.div`
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  padding: ${({size}) => size * 10 / 100}px;
  border: ${({size}) => size * 20 / 100}px solid #f3f3f3;
  border-top: ${({size}) => size * 20 / 100}px solid #002E24;
  border-radius: 50%;
  animation: ${spinner} 1.5s linear infinite;
`;

export const MessageError = styled.p`
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  color: ${(props) => props.theme.colors.warning};
`;