import {
  PANEL_HOME,
  PANEL_TO_MANAGE,
  PANEL_UNIT,
  PANEL_SYSTEM,
  PANEL_ENVIROMENTS,
  PANEL_PRIORITY,
  PANEL_FILA,
  PANEL_PROFILE,
} from "../actionTypes";

const initialState = {
  panelView: "Home",
};

export default function panelReducer(state = initialState, action) {
  switch (action.type) {
    case PANEL_HOME:
      return {
        ...state,
        panelView: action.payload,
      };
    case PANEL_TO_MANAGE:
      return {
        ...state,
        panelView: action.payload,
      };
    case PANEL_UNIT:
      return {
        ...state,
        panelView: action.payload,
      };
    case PANEL_SYSTEM:
      return {
        ...state,
        panelView: action.payload,
      };
    case PANEL_ENVIROMENTS:
      return {
        ...state,
        panelView: action.payload,
      };
    case PANEL_PRIORITY:
      return { ...state, panelView: action.payload };

    case PANEL_FILA:
      return { ...state, panelView: action.payload };

    case PANEL_PROFILE:
      return { ...state, panelView: action.payload };
    default:
      return state;
  }
}
