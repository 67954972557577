import { USER_lOGIN_IN } from '../actionTypes'

const initialState = {
  unidades: []
}

export default function unitReducer(state = initialState, action){
  switch(action.type){
    case USER_lOGIN_IN:
      return action.payload
    default:
      return state
  }
}

