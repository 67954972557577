import React, { useEffect, useRef } from "react";

import {
  Container,
  Content,
  Icon,
  MessageError,
  Label,
  Check,
  ContentCheckBox,
} from "./styles";

export default function CheckBox({
  errorMessage = false,
  checked = false,
  handleCheckBoxChange = {},
  autofocus = false,
  disabled = false,
  label = undefined,
  style = {},
  hideBorder = false,
}) {
  return (
    <Container style={style}>
      <Content>
        <ContentCheckBox>
          <Check
            onChange={handleCheckBoxChange}
            checked={checked}
            disabled={disabled}
          />
          <Label>{label}</Label>
        </ContentCheckBox>
      </Content>
      {errorMessage ? (
        <MessageError>{errorMessage || <br />}</MessageError>
      ) : null}
    </Container>
  );
}
