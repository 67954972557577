import styled from "styled-components";

export const Content = styled.div`
  margin: 1rem 2rem;
  min-height: 16rem;

  background-color: ${(props) =>
    props.isShared === true ? "#66636F" : props.theme.colors.primary};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  transition: 0.2s transform;

  :hover {
    transform: scale(1.1, 1.05);
  }
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  font-size: 20px;
  padding: 10px;
  flex: 3;
`;

export const ImagePC = styled.img`
  height: 4.5rem;
`;
export const Icon = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
`;

export const Guiche = styled.div`
  margin-bottom: 4px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px 0px 10px 10px;

  color: ${(props) => props.theme.colors.white};
  font-size: 0.9rem;
  text-align: center;
  line-height: 1.2rem;
  min-height: 50px;
`;
