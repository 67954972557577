import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

import {
  ButtonAttendance,
  ButtonTitle,
  ButtonDescribe,
  Container
} from './styles'

export default function ButtonInfo({
  title = '',
  describe = '',
  isDisabled = false,
  handleClick
}){
  return(
    <Container isDisabled={isDisabled}>
      <ButtonAttendance onClick={() => handleClick()}> 
        <ButtonTitle isDisabled={isDisabled}>{title}</ButtonTitle>
        <ButtonDescribe isDisabled={isDisabled}>{describe}</ButtonDescribe>
      </ButtonAttendance>
      <MdKeyboardArrowRight color={isDisabled ? "#87858C" : "#fff"} />
    </Container>
  )
}