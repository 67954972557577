import React from "react";
import { MdOutlineLooks3, MdOutlineLooksOne, MdOutlineLooksTwo } from "react-icons/md";
import { useTheme } from "styled-components";

import {
  Content,
  ContainerCircle,
  Circle,
  LineProgress,
  Title,
  Area,
} from './styles'

export default function ProgressBar({
  stage = 0
}){
  const theme = useTheme();

  return(
    <Content>
      <Area isFirst>
        <ContainerCircle>
          <Circle stepActive={stage >= 1} >
            <MdOutlineLooksOne color={stage >= 1 ? theme.colors.white : theme.colors.border}/>
          </Circle>
          <Title>Aguardando</Title>
        </ContainerCircle>
        <LineProgress stepActive={stage >= 1} />
      </Area>
      <Area isMiddle>
        <ContainerCircle>
          <Circle stepActive={stage >= 2}>
            <MdOutlineLooksTwo color={stage >= 1 ? theme.colors.white : theme.colors.border}/>
          </Circle>
          <Title>Em atendimento</Title>
        </ContainerCircle>
        <LineProgress stepActive={stage >= 2} />
      </Area>
      <Area isLast>
        <ContainerCircle>
          <Circle stepActive={stage === 3}>
            <MdOutlineLooks3 color={stage >= 1 ? theme.colors.white : theme.colors.border}/>
          </Circle>
          <Title>Finalizado</Title>
        </ContainerCircle>
      </Area>
    </Content>
  )
}