import styled from "styled-components";

export const Content = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 10px;
  font-size: 70px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3C3C3B;
  margin-right: 30px;
`;
