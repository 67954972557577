import styled, { keyframes } from "styled-components";

const formAnimate = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-40px);
  }
  100% {
  }
`;

export const Content = styled.div`
  width: 100%;
  height: ${(props) => `${props.setHeight}%`};
  display: flex;
  flex-direction: column;
`;

export const Content2 = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;
export const Blocker = styled.div`
  width: ${(props) => (props.setWidth === "none" ? "60%" : "100%")};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  width: 100%;
  height: 10%;
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.primary};
`;

export const ContainerUnits = styled.div`
  display: grid;
  overflow: auto;
  max-height: calc(100vh - 220px);
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  row-gap: 20px;
  overflow-x: auto;
  padding: 10px;
`;

export const ContainerInfos = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
`;

export const ContainerHelpers = styled.div`
  width: 60%;
  height: 90%;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Helper = styled.div`
  width: 100%;
  height: 30%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  cursor: pointer;
  :hover {
    transform: scaleX(1.01);
  }
`;

export const SendBy = styled.div`
  font-weight: 500;
`;

export const Message = styled.div`
  font-weight: 500;
`;

export const PanelAttendence = styled.div`
  width: 55%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ContentGrid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ConteinerGrid = styled.div`
  width: ${(props) => (props.setWidth === "none" ? "100%" : "50%")};
  height: 100%;
  display: ${(props) => props.setDisplay};
  :last-child {
    padding-top: 20px;
  }
`;

export const ContainerButtons = styled.div`
  width: ${(props) => (props.setWidth === true ? "60%" : "100%")};
  height: ${(props) => (props.setHeight ? `${props.setHeight}%` : "20%")};
  margin-top: 20px;
`;

export const ContainerOptionsWindow = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray};
  border-radius: 10px;
  animation: ${formAnimate} 1s;
  display: ${(props) => (props.setDisplay === true ? "block" : "none")};
`;

export const ContainerNoOptions = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: ${(props) => props.theme.colors.white};
`;

export const TitleOptions = styled.div`
  width: 100%;
  height: 10%;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 10px 10px 0 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: ${(props) => props.theme.colors.white};
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: 90%;
  padding: 10px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;

  > button {
    /* TODO: Replace button customization */
    background-color: ${({ theme }) => theme.colors.primary};
    margin: 10px 20%;
  }

  > svg {
    align-self: center;
    margin-top: 20px;

    > path {
      stroke: blue;
    }
  }
`;

export const UnitsSelecteds = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
`;

export const UnitSelected = styled.div`
  width: 20%;
  height: 80%;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.tertiary};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 500;
  margin-right: 10px;
  cursor: pointer;
`;

export const ManageUsers = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentFilter = styled.div`
  flex: 1;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const ContentUsers = styled.div`
  width: 100%;
  height: 85%;
`;

export const CloseWindow = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  :hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const UnitView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 10%;
  height: 100%;
  background: linear-gradient(191.8deg, #110761 -34.05%, #29abe3 172.66%);
  color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
  :hover {
    opacity: 0.8;
    transform: translateY(-5px);
  }
  > svg {
    margin-bottom: 10px;
  }
`;

export const ContainerEnvironment = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  width: 100%;
  height: 100%;
  padding-top: 20px;
`;

export const Environment = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    transform: translateX(10px);
  }
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
`;

export const Icon = styled.div`
  width: 10%;
  height: 50%;
  margin-right: 20px;
`;

export const ContentGlobal = styled.div`
  flex: 1;
  overflow-x: auto;
`;

export const ButtonHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LayerTitle = styled.div`
  width: 90%;
  align-self: center;
  font-size: 32px;
  margin: 0 10px;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: "Titillium Web";
`;

export const LayerButtons = styled.div`
  display: grid;
  min-height: 72px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  align-items: center;
  margin: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  > button {
    white-space: nowrap;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const ButtonAdmin = styled.div`
  border-radius: 10px;
  border: none;
  background-color: ${(props) => props.theme.colors.primary};
  display: flex;
  transition: 0.3s transform;
  cursor: pointer;
  padding: 25px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  :hover {
    transform: scale(1.02);
  }
`;

export const LinkButtonAdmin = styled.a`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  transition: 0.3s;
  cursor: pointer;

  :hover {
    /* transform: scale(1.02); */
  }
`;

export const ButtonAdminTitle = styled.div`
  background-color: ${(props) =>
    props.isActive === true
      ? props.theme.colors.gray
      : props.theme.colors.primary};
  border-radius: 10px 0px 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  color: ${(props) => props.theme.colors.white};
`;

export const ButtonTitle = styled(ButtonAdminTitle)`
  flex: 1;
  padding: 10px;
`;

export const ButtonAdminDescribe = styled.div`
  flex: 3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  padding: 10px;
  border-width: 2px 2px 2px 0;
  border-color: ${({ theme }) => theme.colors.white};
  border-style: solid;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  :hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 1030px) {
    width: 65%;
  }
`;

export const NotFound = styled.p`
  text-align: center;
  margin-top: 15px;
`;

export const ManageUnits = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
`;

export const Rows = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
`;

export const RowItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.white};
  justify-content: flex-start;
  align-items: center;

  :last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const RowInfo = styled.div`
  flex: 2;
`;

export const RowAction = styled.div`
  flex: ${({ large }) => (large ? 1 : 0.5)};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const Item = styled.div`
  text-align: ${({ isBroken }) => isBroken ? 'center' : 'start'};
  color: ${({ theme }) => theme.colors.textGray};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  > button {
    margin-bottom: 0px;
    padding: 11px 30px;
    white-space: nowrap;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  background-color: ${(props) => props.color};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  transition: 0.1s;
  :hover {
    opacity: 0.8;
  }
`;

export const MessageError = styled.p`
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  color: ${(props) => props.theme.colors.warning};
`;

export const FilterForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0px 10px;
`;

export const InputContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: space-around;

  > div > div > select {
    margin: 7px 0;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  border-radius: 8px;
  color: white;
  padding: 16.5px 15px;
  align-self: center;
  font-size: 16px;
  transition: 0.4s opacity;
  margin-bottom: 5px;

  :hover {
    opacity: 0.6;
  }
`;

export const SelectedRules = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-auto-rows: 1fr;
  overflow-x: auto;
  width: 100%;
  margin: 5px 0;
  padding: 5px;
`;

export const Rule = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 4px;
  padding: 3px 7px;
  color: ${({ theme }) => theme.colors.textGray};
  margin: 5px;
  text-align: center;
  justify-content: space-between;
  align-items: center;

  > svg {
    :hover {
      cursor: pointer;
      fill: ${({ theme }) => theme.colors.warning};
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 10px 0 0;
`;

export const FormContainer = styled.div`
  margin: 10px;
  padding: 1rem 1.5rem;
  width: 48%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const FormTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
`;

export const Forms = styled.div`
  display: flex;
  height: 100%;
  padding: 25px;
  overflow: auto;
`;

export const PriorityInputContainer = styled.div`
  display: grid;
  grid-template-columns: auto 5%;
`;

export const PriorityValue = styled.span`
  text-align: center;
  align-self: center;
`;

export const Header = styled.h4`
  margin: 5px 15px;
`;

export const ContentInput = styled.div`
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;
export const ContentButton = styled.div`
  width: 100%;
  margin: 5px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;

  > button {
    margin: 0;
    padding: 0.8rem 4rem;
  }
`;

export const Label = styled.p`
  font-size: 0.9rem;
  text-align: left;
  line-height: 1.5rem;
  font-weight: 600;
  width: 100%;
  color: #333333;
`;

export const ContentLine = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  > p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #87858c;
  }
`;
export const Line = styled.div`
  box-sizing: border-box;
  height: 100%;
  border-left: 1px solid #87858c;
`;


export const LinkContent = styled.a`
  padding: 5px;
  width: 100%;
  overflow-wrap: break-word;
`;