import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Scanner from "react-webcam-qr-scanner";
import {
  Content,
  Container,
  Title,
  ContentButton,
  FormLogin,
  ContentInput,
  Subtitle,
  ContainerQRCode,
  Description,
  ButtonContent,
  Header,
  Body,
  Line,
} from "./styles";
import { TiSortNumerically } from "react-icons/ti";
import Button from "../../components/Forms/Button";
import Input from "../../components/Forms/Input";
import Layout from "../../components/Layout";
import { toast } from "../../utils/toast";

import Cookie from "js-cookie";
import API from "../../services/apiService";

export default function ValidateCodeQrCode() {
  const params = useParams();
  const navigate = useNavigate();
  const schema = yup
    .object({
      name: yup.string().required("Campo obrigatório"),
    })
    .required();

  const handleDecode = (result) => {
    if (result.data) {
      navigate(`/info-senha/${result.data}`);
    }
  };

  async function onSubmit(data) {
    if (data === undefined) {
      return null;
    }
    await API.get(`/senhas/${data.name.replaceAll(" ", "")}`)
      .then((response) => {
        navigate(`/info-senha/${data.name.replaceAll(" ", "")}`);
      })
      .catch((err) => {
        toast(
          "error",
          "Erro",
          err?.response?.data?.message ||
            "Não foi possível buscar as informações"
        );
      });
  }

  const cookie = Cookie.get("token");

  useEffect(() => {
    if (!Cookie.get("token")) {
      navigate("/");
    }
  }, [cookie, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Layout hideProfile hideAside attendance>
      <Content>
        <Header>
          <ButtonContent className="voltar">
            <Button
              className="voltar"
              text="<"
              HandleClick={() => navigate(`/totem/${params.id}`)}
            />
          </ButtonContent>
          <Title>Validação de Senha</Title>
          <ButtonContent>
            <Button
              text="Cancelar"
              HandleClick={() => navigate(`/totem/${params.id}`)}
            />
          </ButtonContent>
        </Header>
        <Body>
          <Container>
            <Subtitle>QR code</Subtitle>
            <Description>
              Para validar aponte o QRCode para a câmera e aguarde a senha.
            </Description>
            <Scanner
              className="some-classname"
              onDecode={handleDecode}
              constraints={{
                audio: false,
                video: {
                  width: { ideal: 1280 },
                  height: { ideal: 720 },
                  facingMode: "environment",
                },
              }}
            />
          </Container>
          <ContainerQRCode>
            <Line />
            <p>ou</p>
            <Line />
          </ContainerQRCode>
          <Container>
            <Subtitle>Código da senha</Subtitle>
            <Description></Description>
            <FormLogin onSubmit={handleSubmit(onSubmit)}>
              <ContentInput>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      LeftIcon={<TiSortNumerically size={22} />}
                      TextInput="Código"
                      value={value}
                      handleChange={(e) => onChange(e)}
                      typeInput={"text"}
                      errorMessage={errors.name?.message}
                    />
                  )}
                />
              </ContentInput>
              <ContentButton>
                <Button text="Validar Senha" typeButton={"submit"} />
              </ContentButton>
            </FormLogin>
          </Container>
        </Body>
      </Content>
    </Layout>
  );
}
