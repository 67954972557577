import styled, { css } from "styled-components";

export const Container = styled.div`
  margin: 5px 0px;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  align-items: center;

  :hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  :active {
    border-color: ${({ theme }) => theme.colors.primary};
    -webkit-box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.colors.primary};
    box-shadow: 0px 0px 6px 0px ${({ theme }) => theme.colors.primary};
  }

  ${({ hideBorder }) =>
    hideBorder
      ? css``
      : css`
          border-width: 1px;
          border-color: ${({ theme }) => theme.colors.secondary};
          border-style: solid;
          border-radius: 10px;
        `}
`;

export const ContentInput = styled.input`
  border: none;
  flex: 1;
  transition: 0.2s;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.33rem 0rem;
  padding-left: 5px;
  vertical-align: center;

  ::placeholder {
    color: hsl(0, 0%, 50%);
  }
`;

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  color: ${(props) => (props.focusEffect === false ? "#E7E8ED" : "#333333")};
  > svg#integration {
    > path {
      stroke: #0280f8;
    }
  }
`;

export const MessageError = styled.p`
  font-size: 12px;
  font-weight: 300;
  margin: 3px 0;
  color: ${(props) => props.theme.colors.warning};
`;

export const Label = styled.p`
  margin-bottom: 5px;
`;
