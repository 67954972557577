import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 10px 10px;
  min-height: 40px;
  align-items: flex-end;
`;

export const Title = styled.span`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.secondary};
  align-self: center;
  font-family: 'Titillium Web';
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
`;