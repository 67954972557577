import { USER_lOGIN_IN, USER_LOGOUT } from '../actionTypes'

export function userLogIn(data){
  return{
    type: USER_lOGIN_IN,
    payload: data
  }

}

export function logOut() {
  return {
    type: USER_LOGOUT,
    payload: { user: {}},
  };
}
