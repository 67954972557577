import { useEffect, useState } from "react";
import { DateText } from "./styles";

export function Timer() {
  const [date, setDate] = useState(new Date());

  function formatDate(date){
    const temp = date.toLocaleDateString("pt-BR",{
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    })
    return temp.replace(',', ' -')
  }

  useEffect(() => {
    // const time = new Date();
    // const lastTime = new Date(date);
    // setTimeout(() => {
    //   if(time.getSeconds() !== lastTime.getSeconds()){
    //     // setDate(`${time.getDate()}/${time.getMonth()}/${time.getFullYear()} -
    //     // ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`)
    //     ConverterDate(time)
    //   }else{
    //     ConverterDate(time)
    //   }
    // }, 1000)
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    }
  }, []);

  return (
    <DateText>{formatDate(date)}</DateText>
  );
}