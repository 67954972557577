import styled, {css} from "styled-components";

import { FaUsers } from 'react-icons/fa'
import { MdKeyboardArrowRight } from 'react-icons/md'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 15px 20px;
  margin: 5px 15px;
  border-radius: 16px;
  color: ${ props => props.theme.colors.white };
  transition: 0.2s transform;

  :disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

export const ILeft = styled(FaUsers).attrs({
  size: 32
})``
