import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { endCronometer } from "../../store/Actions/cronometerAction";

import {
  Content
} from './styles'

export default function Cronometro({
  Active = false
}){

  const dispatch = useDispatch()

  const cronometer = useSelector(state => state.cronometer)

  const [isActive] = useState(Active)
  const [time, setTime] = useState({minutes: 0, seconds: 15})

  function IniciarCronometro(){
    if(time.minutes === 0){
      if(time.seconds === 0){
        dispatch(endCronometer())
        setTime({minutes: 0, seconds: 15})
      }else{
        setTime({...time, seconds: time.seconds -1})
      }
    }    
  }
  function ResetCronometer(){
    setTime({minutes: 0, seconds: 15})
  }
  
  // Aqui precisa refatorar
  useEffect(() => {
    if(cronometer.isStart){
      setTimeout(IniciarCronometro, 1000)
    }else{
      if(time.seconds !== 15){
        ResetCronometer()
      }      
    }
  },[cronometer, time])

  return(
    <Content setActive={isActive}>{`${time.minutes}:${time.seconds < 10 ? '0'+time.seconds : time.seconds}`}</Content>
  )
}