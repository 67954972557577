import React, { useEffect, useRef } from "react";

import { Container, Content, ContentInput, Icon, MessageError, Label } from "./styles";

export default function Input({
  typeInput = "text",
  TextInput = "",
  LeftIcon,
  errorMessage = false,
  value,
  handleChange,
  HandleKey,
  registerValue,
  valueMax = null,
  valueMin = null,
  valueStep = null,
  autofocus = false,
  disabled = false,
  label = undefined,
  style = {},
  hideBorder = false,
  forceRangeValue = false,
}) {
  const inputRef = useRef();
  // const [EnterInput, setEnterInput] = useState(false)

  useEffect(() => {
    if (autofocus) {
      inputRef.current?.focus(); // Force autofocus
    }
  });

  return (
    <Container style={style}>
      {label ? <Label>{ label }</Label> : null}
      <Content hideBorder={hideBorder}>
        {LeftIcon ? <Icon focusEffect={true}>{LeftIcon}</Icon> : null}
        <ContentInput
          ref={inputRef}
          type={typeInput}
          placeholder={TextInput}
          focusEffect={true}
          value={value}
          // onFocus={() => setEnterInput(true)}
          // onBlur={() => setEnterInput(false)}
          onWheel={(e) => e.target.blur()}
          onChange={(e) => handleChange(e.target.value)}
          onKeyPress={(keyPess) => (HandleKey ? HandleKey(keyPess.key) : null)}
          {...registerValue}
          maxLength={forceRangeValue ? null : valueMax}
          minLength={forceRangeValue ? null : valueMin}
          max={valueMax ? valueMax : null}
          min={valueMin ? valueMin : null}
          step={valueStep ? valueStep : null}
          autoFocus={autofocus}
          disabled={disabled}
        />
      </Content>
      {errorMessage ? (
        <MessageError>{errorMessage || <br />}</MessageError>
      ) : null}
    </Container>
  );
}
