import { OPEN_LOADING, CLOSE_LOADING } from '../actionTypes';


const initialState = {
  setDisplay: 'none'
}

export default function loadingReducer(state = initialState, action){
  switch(action.type){
    case OPEN_LOADING:
      return{
        ...state,
        setDisplay: action.payload
      }
    case CLOSE_LOADING:
      return{
        ...state,
        setDisplay: action.payload
      }
    default:
      return state;
  }
}