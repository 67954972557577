import React from "react";
import { QRCodeCanvas } from "qrcode.react";

import {
  Content,
  LogoName,
  Datetime,
  Key,
  Unit,
  ContentQrCode,
  ContentLogo,
  Attendance,
} from "./styles";

import SoftlabLogo from "../../assets/Logos/GroupLogoPass.svg";
import { getKeyNumber, getPriorityAbbreviation } from "../../utils/format";

export default function ToPrint({
  referency,
  active,
  setKey,
  typeAttendance,
  priority,
  unitName,
  unitId,
  link,
}) {
  function dateConverter(date) {
    const data = new Date(date);
    const newDate = `${data.getDate()}/${
      data.getMonth() < 10 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1
    }/${data.getFullYear()}`;
    const newHour = `${
      data.getHours() < 10 ? "0" + data.getHours() : data.getHours()
    }:${data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes()}`;
    return `${newDate} - ${newHour}`;
  }

  return (
    <Content ref={referency} setDisplay={active}>
      <ContentLogo>
        <LogoName src={SoftlabLogo} />
      </ContentLogo>
      <Unit>{unitName}</Unit>
      <Attendance>Tipo de atendimento:</Attendance>
      {/* <Attendance>
        {`Atendimento ${setKey?.prioridade_id ? "Preferencial" : "Normal"}`}
      </Attendance> */}
      <Attendance>{priority ? priority?.nome_prioridade : ""}</Attendance>
      <Datetime>{dateConverter(setKey.createdAt)}</Datetime>
      <Key>{getPriorityAbbreviation(setKey.numero_senha)}</Key>
      <Key>{getKeyNumber(setKey.numero_senha)}</Key>
      <ContentQrCode>
        <QRCodeCanvas value={`${link}/senha/${unitId}?senha_id=${setKey?.id}`} />
      </ContentQrCode>
    </Content>
  );
}
