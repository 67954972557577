import React, { useEffect } from "react";
import Cookie from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form } from "../../../styles";

import { FaBuilding, FaEdit } from "react-icons/fa";
import { BiToggleRight } from "react-icons/bi";

import Api from "../../../../../services/apiService";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../components/Forms/Input";
import Button from "../../../../../components/Forms/Button";
import Select from "../../../../../components/Forms/Select";
import { toast } from "../../../../../utils/toast";

export default function Environment({
  isUpdateEnvironment,
  isCreateAmbiente,
  setToLoad,
  closeWindowModal,
  unit,
  environment,
}) {

  const schema = yup.object().shape({
    name: yup.string().required("Nome do ambiente obrigatório."),
    description: yup.string().required("Descrição obrigatória."),
    status: yup.string(),
  });

  const defaultValues = {
    name: "",
    description: "",
    status: "0",
  };

  const {
    control,
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    clearErrors();
    if (isUpdateEnvironment) {
      setValue("name", environment.nome_ambiente);
      setValue("description", environment.desc_ambiente);
      setValue("status", environment.status || "0");
    } else {
      reset();
    }
  }, [isUpdateEnvironment, environment]);

  async function onSubmitAmbiente(data) {
    try {
      let newData = {
        nome_ambiente: data.name,
        desc_ambiente: data.description,
        uso_compartilhado: false,
        unidade_id: unit,
      };

      await Api.post("/ambientes", newData);
      toast("success", "Excelente", "Ambiente criado com sucesso!");
      closeWindowModal();
      setToLoad(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Ocorreu um erro ao criar o ambiente."
      );
    }
  }

  async function onSubmitEnvironmentUpdate(dataPayload) {
    try {
      const payload = {
        nome_ambiente: dataPayload.name,
        desc_ambiente: dataPayload.description,
        status: dataPayload.status,
      };

      const { data } = await Api.put(
        `/ambientes/${environment.id}`,
        payload
      );
      toast(
        "success",
        "Sucesso",
        data?.message || "Ambiente atualizado com sucesso!"
      );
      closeWindowModal();
      setToLoad(true); // TODO: Change reload method
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível atualizar o ambiente"
      );
    }
  }

  return (
    <Modal
      modalWidth={50}
      modalHeight={55}
      modalTitle={(isUpdateEnvironment ? "Editar" : "Cadastrar") + " ambiente"}
      modalActive={isCreateAmbiente}
      modalSetActive={() => closeWindowModal()}
    >
      <Form>
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FaBuilding size={22} />}
              width="100"
              height="15"
              TextInput="Nome do ambiente"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={errors?.name ? errors?.name.message : null}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FaEdit size={22} />}
              width="100"
              height="15"
              TextInput="Breve Descrição do ambiente"
              typeInput={"text"}
              value={value}
              handleChange={onChange}
              errorMessage={
                errors?.description ? errors?.description.message : null
              }
            />
          )}
        />
        {isUpdateEnvironment ? (
          <Controller
            control={control}
            name="status"
            render={({ field: { onChange, value } }) => (
              <Select
                width="100"
                height="15"
                value={value}
                placeholder="Status"
                defaultTitle="Selecione um status"
                handleChange={onChange}
                errrorMessage={errors?.status?.message}
                LeftIcon={<BiToggleRight size={22} />}
              >
                <option value="0">Ativo</option>
                <option value="2">Inativo</option>
              </Select>
            )}
          />
        ) : null}
        <Button
          width={30}
          height={25}
          text={isUpdateEnvironment ? "Atualizar" : "Cadastrar"}
          typeButton="button"
          HandleClick={handleSubmit(
            isUpdateEnvironment ? onSubmitEnvironmentUpdate : onSubmitAmbiente
          )}
        />
      </Form>
    </Modal>
  );
}
