import { addDays } from ".";

function setItem(key, value) {
  const baseDate = new Date();
  const limit = addDays(baseDate, 1);
  limit.setUTCHours(3, 0, 0);

  const item = {
    value,
    expiry: limit.getTime(),
  }

  localStorage.setItem(key, JSON.stringify(item));
}

function getItem(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) { // TODO: Change current timezone
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export { setItem, getItem };