import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { FiUnlock } from "react-icons/fi";
import { SiMaildotru } from "react-icons/si";
import Input from "../../../components/Forms/Input";
import Button from "../../../components/Forms/Button";
import Api from "../../../services/apiService";
import Barcode from "react-barcode";
import { toast } from "../../../utils/toast";
import {
  ButtonContainer,
  Content,
  ContentButton,
  ContentInput,
  ContentLine,
  Form,
  FormContainer,
  Forms,
  FormTitle,
  Label,
  LayerTitle,
  Line,
} from "../styles";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/Layout";
import { setPanelShowOptions } from "../../../store/Actions/clinicAction";
import {
  closeLoadingModal,
  openLoadingModal,
} from "../../../store/Actions/loadingAction";
import { BiUser } from "react-icons/bi";

export default function Profile({ isAdmin }) {
  const dispatch = useDispatch();
  const [link, setLink] = useState("");
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    getHref();
  }, []);
  function getHref() {
    const Href = window.location.href.toString();

    setLink(Href.split("/"));
  }
  const defaultValues = {
    name: "",
    username: "",
    email: "",
  };
  const passwordChangeDefaultValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const schema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    username: yup.string().required("Campo obrigatório"),
    email: yup
      .string()
      .email("Digite um e-mail válido")
      .required("Campo obrigatório"),
  });

  const passwordValidation = yup.object().shape({
    oldPassword: yup.string().required("Senha atual obrigatória"),
    newPassword: yup.string().required("Nova senha é obrigatória"),
    confirmNewPassword: yup
      .string()
      .required("Confirmação de senha obrigatória")
      .test("passwords-match", "As senhas não coincidem", function (value) {
        return this.parent.newPassword === value;
      }),
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    control: passwordController,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors },
  } = useForm({
    defaultValues: passwordChangeDefaultValues,
    resolver: yupResolver(passwordValidation),
  });

  function setupDefaultValues(user) {
    setValue("name", user?.nome || "");
    setValue("email", user?.email || "");
    setValue("username", user?.username || "");
  }

  async function onSubmit(payload) {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.put(
        `/usuarios/${user.id}`,
        {
          nome: payload.name,
          email: payload.email,
          username: payload.username,
        }
      );
      toast(
        "success",
        "Sucesso!",
        data?.message || "Perfil atualizado com sucesso!"
      );
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível editar a perfil."
      );
    }
    dispatch(closeLoadingModal());
  }

  async function changePassword(payload) {
    try {
      dispatch(openLoadingModal());
      const { data } = await Api.put(
        `usuarios/trocar-senha/${user.id}`,
        {
          oldPassword: payload.oldPassword,
          newPassword: payload.confirmNewPassword,
        },
      );
      toast(
        "success",
        "Sucesso",
        data?.message || "Senha alterada com sucesso!"
      );
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível alterar sua senha"
      );
    }
    dispatch(closeLoadingModal());
  }

  useEffect(() => {
    setupDefaultValues(user);
  }, []);

  useEffect(() => {
    dispatch(setPanelShowOptions(true));
    return () => {
      dispatch(setPanelShowOptions(false));
    };
  }, []);

  // For use outside admin
  const Root = isAdmin ? React.Fragment : Layout;

  return (
    <Root attendance showAllAttendance>
      <Content>
        <a
          style={{ position: "absolute", minWidth: 74, left: 74 }}
          href={`${link[0]}`}
        >
          <ButtonContainer>
            <Button text="<" />
          </ButtonContainer>
        </a>
        <LayerTitle style={{ textAlign: "center" }}>Meu perfil</LayerTitle>
        <Forms>
          <FormContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormTitle>Edite suas informações pessoais</FormTitle>
              <ContentInput>
                <Label>
                  Nome do usuário <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      LeftIcon={<BiUser size={22} />}
                      width="100"
                      height="10"
                      TextInput="Digite o nome do usuário"
                      typeInput={"text"}
                      value={value}
                      handleChange={onChange}
                      errorMessage={errors.name?.message}
                    />
                  )}
                />
              </ContentInput>
              <ContentInput>
                <Label>
                  Login do usuário <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={control}
                  name="username"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      LeftIcon={<BiUser size={22} />}
                      width="100"
                      height="10"
                      TextInput="Digite o login do usuário"
                      typeInput={"text"}
                      value={value}
                      handleChange={onChange}
                      errorMessage={errors.username?.message}
                    />
                  )}
                />
              </ContentInput>
              <ContentInput>
                <Label>
                  E-mail <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      LeftIcon={<SiMaildotru size={22} />}
                      width="100"
                      height="10"
                      TextInput="Digite o e-mail do Usuário"
                      typeInput={"email"}
                      value={value}
                      handleChange={onChange}
                      errorMessage={errors.email?.message}
                    />
                  )}
                />
              </ContentInput>
              <ContentButton>
                <Button
                  width={30}
                  height={10}
                  text="Alterar"
                  typeButton={"submit"}
                />
              </ContentButton>
              <Barcode
                value={user.cod_usuario_integrado || user.id}
                width={1}
              />
            </Form>
          </FormContainer>
          <ContentLine>
            <Line />
            <p>ou</p>
            <Line />
          </ContentLine>
          <FormContainer>
            <Form onSubmit={handlePasswordSubmit(changePassword)}>
              <FormTitle>Altere sua senha</FormTitle>
              <ContentInput>
                <Label>
                  Sua senha atual <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={passwordController}
                  name="oldPassword"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      LeftIcon={<FiUnlock size={22} />}
                      width="100"
                      height="10"
                      TextInput="Informe sua atual senha"
                      typeInput={"password"}
                      value={value}
                      handleChange={onChange}
                      errorMessage={passwordErrors?.oldPassword?.message}
                    />
                  )}
                />
              </ContentInput>
              <ContentInput>
                <Label>
                  Nova senha <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={passwordController}
                  name="newPassword"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      LeftIcon={<FiUnlock size={22} />}
                      width="100"
                      height="10"
                      TextInput="Informe sua nova senha"
                      typeInput={"password"}
                      value={value}
                      handleChange={onChange}
                      errorMessage={passwordErrors?.newPassword?.message}
                    />
                  )}
                />
              </ContentInput>
              <ContentInput>
                <Label>
                  Confirmar nova senha <span style={{ color: "red" }}>*</span>
                </Label>
                <Controller
                  control={passwordController}
                  name="confirmNewPassword"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      LeftIcon={<FiUnlock size={22} />}
                      width="100"
                      height="10"
                      TextInput="Confirme sua nova senha"
                      typeInput={"password"}
                      value={value}
                      handleChange={onChange}
                      errorMessage={passwordErrors?.confirmNewPassword?.message}
                    />
                  )}
                />
              </ContentInput>
              <ContentButton>
                <Button text="Alterar senha" typeButton={"submit"} />
              </ContentButton>
            </Form>
          </FormContainer>
        </Forms>
      </Content>
    </Root>
  );
}
