import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Content,
  Container,
  Title,
  ContainerTypeAttendance,
  ContainerSelectUnit,
  SelectContent,
} from "./styles";

import Select from "../../components/Forms/Select";
import Guiche from "../../components/Guiche";
import Loading from "../../components/Loading";
import Layout from "../../components/Layout";
import Api from "../../services/apiService";
import {
  setClinicData,
  setPanelShowOptions,
  setUnitData,
} from "../../store/Actions/clinicAction";
import { toast } from "../../utils/toast";
import { MdBusiness } from "react-icons/md";

export default function SelectUnit() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState({
    guiches: [],
    unidade_id: "",
  });

  async function loadUnits() {
    setLoading(true);
    try {
      const { data } = await Api.get("/unidade-guiches");
      if (data) setUnits(data);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message ||
          "Não foi possível carregar sua lista de unidades"
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadUnits();
  }, []);

  function SelectedUnit(index) {
    if (index !== "default") {
      setSelectedUnit({ guiches: units[index].guiches, unidade: units[index] });
      dispatch(setUnitData(units[index]));
      dispatch(setPanelShowOptions(true));
    }
  }

  function goToAttendenceScreen(data) {
    dispatch(setClinicData(data));
    if (data.guiche.uso_compartilhado) {
      navigate(`/compartilhado/${data.unidade.id}`);
    } else {
      navigate(`/unidade/${data.unidade.id}/atendimento/${data.guiche.id}`);
    }
  }

  function goToGerenteScreen(data) {
    dispatch(setClinicData(data));
    navigate(`/admin`);
  }

  function sortByName(a, b) {
    if (a.nome_guiche < b.nome_guiche) return -1;
    else if (a.nome_guiche > b.nome_guiche) return 1;
    return 0;
  }

  function handleProfile() {
    navigate("/profile");
  }

  return (
    <Layout attendance handleProfile={handleProfile}>
      <Content>
        {loading ? (
          <Loading setDisplay={loading} />
        ) : (
          <Container>
            <Title>Selecione a unidade de atendimento</Title>
            <ContainerTypeAttendance>
              {user.perfil[0] !== 2 ? (
                <>
                  <SelectContent>
                    <Select
                      LeftIcon={<MdBusiness />}
                      defaultTitle="Selecione a unidade"
                      handleChange={(e) => SelectedUnit(e)}
                    >
                      {units.map((unit, index) => (
                        <option key={index} value={index}>
                          {unit.unidade}
                        </option>
                      ))}
                    </Select>
                  </SelectContent>
                  <ContainerSelectUnit>
                    {selectedUnit.guiches
                      .sort(sortByName)
                      .map((guiche, index) => (
                        <Guiche
                          key={index}
                          name={
                            guiche.uso_compartilhado
                              ? "Guichê Compartilhado"
                              : guiche.nome_guiche
                          }
                          description={
                            guiche.uso_compartilhado
                              ? "Guichê Compartilhado"
                              : guiche.desc_guiche
                          }
                          statusUnit={true}
                          shared={guiche.uso_compartilhado}
                          handleClick={() =>
                            goToAttendenceScreen({
                              unidade: selectedUnit.unidade,
                              guiche: guiche,
                            })
                          }
                        />
                      ))}
                  </ContainerSelectUnit>
                </>
              ) : (
                <ContainerSelectUnit>
                  {units.map((unit, index) => (
                    <Guiche
                      key={index}
                      name={unit.unidade}
                      description={unit.desc_unidade}
                      statusUnit={true}
                      shared={false}
                      handleClick={() =>
                        goToGerenteScreen({
                          unidade: unit,
                        })
                      }
                    />
                  ))}
                </ContainerSelectUnit>
              )}
            </ContainerTypeAttendance>
          </Container>
        )}
      </Content>
    </Layout>
  );
}
