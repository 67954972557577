import { SET_CLINIC, SET_UNIT, SET_GUICHE, PANEL_OPTIONS_SHOW_ALL } from '../actionTypes';

export function setClinicData(data){
  return{
    type: SET_CLINIC,
    payload: data
  }
}

export function setUnitData(data){
  return{
    type: SET_UNIT,
    payload: data
  }
}

export function setGuicheData(data){
  return{
    type: SET_GUICHE,
    payload: data
  }
}

export function setPanelShowOptions(showOptions) {
  return {
    type: PANEL_OPTIONS_SHOW_ALL,
    payload: { showOptions },
  };
}