import React from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "../../utils/toast";
import Api from "../../services/apiService";
import { MdAlternateEmail } from "react-icons/md";
import LogoLogin from "../../assets/Logos/GroupLogo.svg";
import Input from "../../components/Forms/Input";
import Button from "../../components/Forms/Button";

import {
  Content,
  ContentInput,
  Container,
  Title,
  Description,
  Logo,
  Label,
  ContentButton,
  ContentRememberPass,
  Anchor,
} from "./styles";

export default function ForgotPassword() {
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Informe um e-mail válido")
      .required("Campo obrigatório"),
  });

  const defaultValues = { email: "" };
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  async function sendEmail(data) {
    try {
      await Api.post("/resetar-senha", data);
      toast(
        "success",
        "Enviado",
        "Foi enviado para seu e-mail uma recuperação de senha!"
      );
      reset();
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível completar essa ação."
      );
    }
  }

  return (
    <Content>
      <Container>
        <Logo src={LogoLogin} alt="SoftPass logo" />
        <Title>Esqueceu sua senha?</Title>
        <Description>
          Digite aqui seu e-mail para enviar-mos a recuperação de senha!
        </Description>
        <ContentInput>
          <Label>
            E-mail <span style={{ color: "red" }}>*</span>
          </Label>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <Input
                LeftIcon={<MdAlternateEmail />}
                TextInput="Digite seu e-mail"
                value={value}
                handleChange={onChange}
                errorMessage={errors?.email?.message}
              />
            )}
          />
        </ContentInput>
        <ContentButton>
          <Button HandleClick={handleSubmit(sendEmail)} text="Recuperar" />
        </ContentButton>
        <ContentRememberPass>
          Lembrou sua senha? <Anchor to="/">Fazer Login</Anchor>
        </ContentRememberPass>
      </Container>
    </Content>
  );
}
