import styled from "styled-components";
import { Link } from "react-router-dom";

export const Content = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.secondary};
  background-size: 40px 40px;
  background-image: radial-gradient(#6d6b70 2px, transparent 2px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 20px 10px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  text-align: center;

  > button {
    margin: 10px 60px;
  }
`;

export const Label = styled.p`
  font-size: 0.9rem;
  text-align: left;
  line-height: 1.5rem;
  font-weight: 600;
  width: 100%;
  color: #333333;
`;

export const ContentInput = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 1.5rem;
  line-height: 2.3125rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.secondary};
  margin: 1.8rem 1.2rem 1rem;
`;

export const Description = styled.p`
  margin: 1rem 0rem;
  width: 70%;
  align-self: center;
  color: ${({ theme }) => theme.colors.textGray};
  font-size: 1rem;
  line-height: 1.125rem;
`;

export const Logo = styled.img`
  width: 170px;
  align-self: center;
`;

export const LayerForgot = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

export const Input = styled.input`
  width: 50%;
  height: 50px;
  margin-top: 20px;
  border: none;
  border-bottom: 2px solid ${(props) => props.theme.colors.secondary};
  text-align: center;
  font-size: 20px;
  @media (max-width: 380px) {
    width: 100%;
  }
`;

export const Icon = styled.div`
  width: 100px;
  height: 40px;
  position: absolute;
  top: 35%;
  left: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 380px) {
    top: 45%;
    left: 4%;
  }
`;

export const ContentButton = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    max-width: 60%;
    padding: 1rem 1.5rem;

    @media (max-width: 768px) {
      max-width: 80%;
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }
`;

export const Button = styled.button`
  width: 15%;
  height: 50px;
  margin-top: 20px;
  border: none;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  border-radius: 10px;
  font-size: 20px;
  @media (max-width: 380px) {
    width: 80%;
  }
`;

export const MessageError = styled.p`
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  width: 100%;
  color: ${(props) => props.theme.colors.warning};
`;

export const ContentRememberPass = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  transition: 0.2s;
  color: ${(props) => props.theme.colors.secondary};
`;

export const Anchor = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
