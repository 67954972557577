const BlueTheme = {
  title: 'BlueTheme',
  colors: {
    primary: '#0280F8',
    secondary: '#333333',
    tertiary: '#E7E8ED',
    quaternary: '#36A8E0',
    login: '#FFFFFF',
    whiteLogin: '#333333',

    white: '#FFFFFF',
    black: '#000000',
    gray: '#c4c4c4',
    lightGray: '#f2f2f2',
    border: '#b2b2b2',
    button: '#E7E8ED',
    textGray: '#87858C',

    success: '#6ECFBD',
    info: '#FFC466',
    warning: '#F76140',
  },
}

export default BlueTheme