import styled from "styled-components";

export const Input = styled.input`
  padding: 10px;
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: inherit;
  font-size: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 8px;
  background-color: inherit;
  height: 45px;
`;

export const Button = styled.div`
  padding: 5px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
`;