import styled from "styled-components";

export const Content = styled.button`
  flex: 1;
  background-color: ${({ theme, color }) => color || theme.colors.primary};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${(props) => props.textColor || props.theme.colors.white};
  font-size: 18px;
  transition: 0.3s;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  padding: 15px 0;
  margin: 10px;

  :hover {
    opacity: 0.8;
  }
  :active,
  :focus {
    -webkit-box-shadow: 0px 0px 8px 0px
      ${({ theme, color }) => color || theme.colors.primary};
    box-shadow: 0px 0px 8px 0px
      ${({ theme, color }) => color || theme.colors.primary};
  }
`;
