import React, { useState, useEffect } from "react";

import Cronometro from "../Cronometro";

import {
  Content,
  Title,
  ContainerKeys,
  Key,
  ContainerAtendimento,
  AttendanceLayer,
  ButtonEncerrar,
  ButtonRed,
  LatestPassword,
  ButtonBlue,
  History,
  Buttons,
  Table,
  Row,
  Column,
  AttendanceContainer,
} from "./styles";

export default function AttendanceScreen({
  attendanceActive = false,
  setPassword,
  closeAttendance,
  encaminhar,
  unitAndGuiche,
  lasts,
}) {
  const [attendanceActived, setAttendanceActived] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    setAttendanceActived(attendanceActive);
    setNewPassword(setPassword);
  }, [attendanceActive, setPassword]);

  function dateConverter(date) {
    const data = new Date(date);
    const newDate = `${data.getDate()}/${
      data.getMonth() <= 10 ? "0" + (data.getMonth() + 1) : data.getMonth() + 1
    }/${data.getFullYear()}`;
    const newHour = `${
      data.getHours() <= 9 ? "0" + data.getHours() : data.getHours()
    }:${data.getMinutes() <= 9 ? "0" + data.getMinutes() : data.getMinutes()}`;
    return `${newDate} - ${newHour}`;
  }

  function maskKey(key) {
    return key === undefined ? null : key.replace(" ", "");
  }

  function decimalTwoPlaces(value) {
    value = value.toString();
    if (value.length === 1) return `0${value}`;
    return value;
  }

  function millisecondsToLocaleTime(diff) {
    const hours = parseInt(diff / 60 / 60 / 1000);
    diff = diff - hours * 60 * 60 * 1000;
    const minutes = parseInt(diff / 60 / 1000);
    diff = diff - minutes * 60 * 1000;
    const seconds = parseInt(diff / 1000);

    return (
      decimalTwoPlaces(hours) +
      `:${decimalTwoPlaces(minutes)}:` +
      decimalTwoPlaces(seconds)
    );
  }

  function calcWaitTime(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);

    let diff = date2.getTime() - date1.getTime();
    return millisecondsToLocaleTime(diff);
  }

  return (
    <Content>
      <ContainerAtendimento>
        {attendanceActived ? null : <Cronometro Active={attendanceActived} />}
        <Key setActive={attendanceActived}>
          {newPassword.numero_senha === undefined
            ? "- -"
            : maskKey(newPassword.numero_senha)}
        </Key>
      </ContainerAtendimento>
      {attendanceActived ? (
        <AttendanceLayer>
          <AttendanceContainer>
            <Title>Atendimento</Title>
            <Table>
              <Row>
                <Column>Unidade</Column>
                <Column style={{ fontWeight: "bold" }}>
                  {unitAndGuiche.unidade.unidade}
                </Column>
              </Row>
              <Row>
                <Column>Atendimento</Column>
                <Column style={{ fontWeight: "bold" }}>
                  {newPassword.tipo_prioridade_id === null
                    ? "Normal"
                    : "Prioridade"}
                </Column>
              </Row>
              <Row>
                <Column>Emissão da senha</Column>
                <Column style={{ fontWeight: "bold" }}>
                  {dateConverter(newPassword.createdAt)}
                </Column>
              </Row>
              <Row>
                <Column>Tempo de espera</Column>
                <Column style={{ fontWeight: "bold" }}>
                  {calcWaitTime(newPassword.createdAt, new Date())}
                </Column>
              </Row>
            </Table>
            <Buttons>
              <ButtonEncerrar>
                <ButtonBlue
                  onClick={() =>
                    encaminhar({ attendance: false, function: "encaminhar" })
                  }
                >
                  Encaminhar senha
                </ButtonBlue>
              </ButtonEncerrar>
              <ButtonEncerrar>
                <ButtonRed
                  onClick={() =>
                    closeAttendance({ attendance: false, function: "end" })
                  }
                >
                  Encerrar atendimento
                </ButtonRed>
              </ButtonEncerrar>
            </Buttons>
          </AttendanceContainer>
        </AttendanceLayer>
      ) : (
        <History>
          {attendanceActived ? null : <Title>Últimas senhas</Title>}
          <ContainerKeys setActive={attendanceActived}>
            {lasts.length > 0
              ? lasts.map((last, index) => (
                  <LatestPassword key={index}>
                    <p>{maskKey(last.numero_senha)}</p>
                    <p style={{ fontWeight: "bold", textAlign: 'center' }}>
                      {last["prioridade.nome_prioridade"] ? last["prioridade.nome_prioridade"] : "Normal"}
                    </p>
                  </LatestPassword>
                ))
              : null}
          </ContainerKeys>
        </History>
      )}
    </Content>
  );
}
