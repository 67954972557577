import React from "react";

import {
  Content,
  Button,
  IRight
} from './styles'

export default function ButtonTypeAttendence({
  children,
  HandleClick,
  isDisabled,
  haveSubItem
}){

  return (
    <Content>
          <Button disabled={isDisabled} onClick={() => HandleClick()}>
            {children}
            {haveSubItem ? (
              <IRight />
            ): null}
          </Button>
    </Content>
  )
}