import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 13px 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: ${props => props.theme.colors.white};
  margin: 1px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const Col = styled.div`
  cursor: ${({ canOrder }) => canOrder ? 'pointer' : 'default'};
  display: flex;
  flex-direction: row;
  ${({ isAction, large, isBroken }) => 
    isAction ? css`
      text-align: center;
      justify-content: center;
      flex: ${large ? 1 : 0.5};
    ` : css`
      text-align: center;
      justify-content: ${isBroken ? 'center' : 'start'};
      padding-right: ${isBroken ? 5: 0}px;
      flex: ${large ? 3 : 2};
    `}
`;