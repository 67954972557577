import React from "react";
import image from "../../assets/Logos/Image-pc.svg";
import { useSelector } from "react-redux";

import {
  Content,
  Image,
  Info,
  Icon,
  Data,
  ImagePC,
} from "./styles";

import ToggleSelect from "../Forms/ToggleSelect";

export default function Unit({
  name = "",
  description = "",
  guiches = "00",
  statusUnit = true,
  handleClick,
  handleDeactivate,
}) {
  const { panelView } = useSelector((state) => state.panel);

  return (
    <Content onClick={() => (handleClick ? handleClick() : null)}>
      <Image>
        {panelView === "Unit" ? (
          <ToggleSelect onClick={handleDeactivate} status={statusUnit} />
        ) : null}
        <Data>
          <Icon>
            <ImagePC src={image} />
          </Icon>
          <span style={{ textAlign: "center", lineHeight: "1.2rem" }}>
            {name}
          </span>
        </Data>
      </Image>
      <Info>{description}</Info>
    </Content>
  );
}
