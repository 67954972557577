import React, { useEffect } from "react";
import Cookie from "js-cookie";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, LinkContent } from "../../../styles";

import { FaEdit } from "react-icons/fa";

import Api from "../../../../../services/apiService";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../components/Forms/Input";
import Button from "../../../../../components/Forms/Button";
import CheckBox from "../../../../../components/Forms/Checkbox";
import { toast } from "../../../../../utils/toast";
import { format } from "date-fns";

export default function Config({
  isUpdate,
  showModal,
  setToLoad,
  closeWindowModal,
  unit,
  unitConfig,
}) {
  const [ host ] = window.location.href.toString().split("/admin");

  const defaultValues = {
    neverExpires: true,
    expirationDate: undefined,
  };

  const schema = yup.object().shape({
    neverExpires: yup.boolean(),
    expirationDate: yup.date()
    .nullable(true)
    .typeError("Informe uma data válida")
    .min(new Date(), "Não é possível usar datas passadas")
    .when('neverExpires', {
        is: false,
        then: item => item.required("Data de expiração obrigatória"),
    })
    .transform((_, value) => value ? new Date(value) : null),
  });

  const {
    control,
    reset,
    setValue,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  function setupEditableFieldsValue() {
    setValue("neverExpires", unitConfig.neverExpires);
    if (unitConfig.expirationDate) {
      const [_date] = unitConfig.expirationDate.split('T');
      setValue("expirationDate", _date);      
    }
  }

  function generatePanelURI() {
    let uri = `${host}/senhas/${unit}`;
    if (unitConfig.token) {
      uri = uri + `/?token=${unitConfig.token}`;
    }
    return uri;
  }

  async function onSubmitCreate(formData) {
    try {
      const payload = {
        neverExpires: formData.neverExpires,
      };

      if (formData.expirationDate) {
        const [_date] = formData.expirationDate.toJSON().split("T");
        payload.expirationDate = _date;
      }
      unitConfig.expirationDate = payload.expirationDate

      const { data } = await Api.post(`/unidades/${unit}/config`, payload);
      toast(
        "success",
        "Sucesso",
        data?.message || "Expiração da URL do painel criada com sucesso!",
      );
      setToLoad(true);
    } catch (error) {
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível criar a expiração"
      );
    }
  }

  async function onSubmitUpdate(formData) {
    try {
      const payload = {
        neverExpires: formData.neverExpires,
      };

      if (formData.expirationDate) {
        const [_date] = formData.expirationDate.toJSON().split("T");
        payload.expirationDate = _date;
      }
      unitConfig.expirationDate = payload.expirationDate

      const { data } = await Api.put(`/unidades/${unit}/config`, payload);
      toast(
        "success",
        "Sucesso",
        data?.message || "Expiração da URL do painel salva com sucesso!",
      );
      setToLoad(true);
    } catch (error) {
      console.error(error);
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível atualizar a expiração"
      );
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "neverExpires" && value.neverExpires)
        setValue("expirationDate", "");
      else if (name === "expirationDate" && value.expirationDate && value.neverExpires)
        setValue("neverExpires", false);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    clearErrors();
    if (isUpdate) {
      setupEditableFieldsValue();
    } else {
      reset();
    }
  }, [isUpdate]);

  return (
    <Modal
      modalWidth={50}
      modalHeight={90}
      modalTitle={(isUpdate ? "Editar" : "Cadastrar") + " configuração"}
      modalActive={showModal}
      modalSetActive={() => closeWindowModal()}
    >
      <Form>
        <Controller
          control={control}
          name="neverExpires"
          render={({ field: { onChange, value } }) => (
            <CheckBox
              label="Não possuir expiração"
              handleCheckBoxChange={onChange}
              checked={value}
              errrorMessage={errors?.neverExpires?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="expirationDate"
          render={({ field: { onChange, value } }) => (
            <Input
              LeftIcon={<FaEdit size={22} />}
              width="100"
              height="10"
              TextInput="Informe a data de expiração"
              typeInput="date"
              valueMin={format(new Date(), 'yyyy-MM-dd')}
              handleChange={onChange}
              value={value}
              errorMessage={
                errors?.expirationDate ? errors?.expirationDate.message : null
              }
            />
          )}
        />
        <LinkContent modalWidth={50} href={generatePanelURI()}>
          {generatePanelURI()}
        </LinkContent>
        <Button
          width={30}
          height={10}
          text={isUpdate ? "Atualizar" : "Cadastrar"}
          typeButton="button"
          HandleClick={handleSubmit(isUpdate ? onSubmitUpdate : onSubmitCreate)}
        />
      </Form>
    </Modal>
  );
}
