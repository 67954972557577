import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  padding: 1.2rem 1rem;
`;
export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  position: relative;
  padding: 1.2rem 1rem;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 2rem;
  line-height: 3rem;
  color: ${(props) => props.theme.colors.secondary};
  flex: 1;
`;

export const Subtitle = styled.div`
  font-size: 2rem;
  text-align: center;
  line-height: 3rem;
  color: ${(props) => props.theme.colors.primary};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  font-size: 1rem;
  text-align: center;
  line-height: 20px;
  color: #87858c;
  margin-bottom: 1rem;
`;

export const Container = styled.div`
  width: 47%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;

  display: flex;
  flex-direction: column;
`;

export const ContainerQRCode = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  > p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: #87858c;
  }
`;
export const Line = styled.div`
  box-sizing: border-box;
  height: 100%;
  border-left: 1px solid #87858c;
`;

export const FormLogin = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentInput = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
`;

export const ContentButton = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    padding: 1rem 1.5rem;
  }
`;

export const ButtonContent = styled.div`
  min-width: 200px;
  > button {
    margin: 0;
    width: 100%;
    padding: 1rem 1.5rem;
  }
  &.voltar {
    button {
      max-width: 4rem;
    }
  }
`;

export const MessageError = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: ${(props) => props.theme.colors.warning};
`;
