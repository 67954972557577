import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookie from "js-cookie";

import Layout from "../../components/Layout";
import Home from "./Panels/Home";
import Units from "./Panels/Units";
import Manage from "./Panels/Manage";
import Priority from "./Panels/Priority";
import Profile from "./Panels/Profile";
import { viewManage, viewProfile } from "../../store/Actions/panelAction";
import { MANAGER, SUPERVISOR } from "../../utils/constants";

export default function Admin() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { panelView } = useSelector((state) => state.panel);
  const token = Cookie.get("token");

  const [tokenValidate, setTokenValidate] = useState(false);

  useEffect(() => {
    if (token !== undefined || token !== false) {
      setTokenValidate(true);
    }
  }, []);

  useEffect(() => {
    // Avoid unauthorized access to dashboard
    if ([MANAGER, SUPERVISOR].includes(user.perfil[0])) {
      dispatch(viewManage());
    }
  }, []);

  function handleProfile() {
    dispatch(viewProfile());
  }

  function homeViewValidation() {
    return (
      panelView === "Home" &&
      user.perfil[0] !== MANAGER &&
      user.perfil[0] !== SUPERVISOR
    );
  }

  return (
    <Layout token={tokenValidate} handleProfile={handleProfile}>
      {homeViewValidation() ? (
        <Home />
      ) : panelView === "Manage" ? (
        <Manage />
      ) : panelView === "Unit" ? (
        <Units />
      ) : panelView === "PRIORITY" ? (
        <Priority />
      ) : panelView === "PROFILE" ? (
        <Profile isAdmin />
      ) : null}
    </Layout>
  );
}
