import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.secondary};
  border-style: solid;
  border-radius: 8px;
  align-items: center;
`;

// export const CheckBox = styled.div`
//   width: 30px;
//   height: 30px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: 0.2s;
//   color: ${(props) => (props.focusEffect === false ? "#E7E8ED" : "#333333")};
//   > svg#integration {
//     > path {
//       stroke: #0280f8;
//     }
//   }
// `;
export const Check = styled.input.attrs({ type: "checkbox" })`
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 10px;
`;

export const ContentCheckBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  padding-left: 0.6rem;
`;

export const Label = styled.label`
  margin-bottom: 5px;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #0b0b0b;
  color: ${(props) => (props.checked ? `#0b0b0b` : `#888888`)};
`;

export const MessageError = styled.p`
  font-size: 12px;
  font-weight: 300;
  margin: 3px 0;
  color: ${(props) => props.theme.colors.warning};
`;
