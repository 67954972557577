import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  display: ${(props) => (props.setDisplay ? "block" : "none")};
`;

export const ContentLogo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const LogoName = styled.img`
  width: 50%;
`;

export const Key = styled.div`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;

  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`;

export const Unit = styled.div`
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
  margin-top: 1.2rem;
`;

export const Attendance = styled.div`
  text-align: center;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 500;
`;
export const Datetime = styled.div`
  text-align: center;
  font-size: 1rem;
  line-height: 1.4rem;
  font-weight: 500;
`;

export const Datetime2 = styled.div`
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 400;
`;
export const ContentQrCode = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
`;
