import React, { useRef, useState, useEffect, useMemo } from "react";
import Header from "../../components/Header";
import ButtonTypeAttendence from "../../components/Forms/ButtonTypeAttendence";
import ButtonPriorities from "../../components/Forms/ButtonPriorities";
import { useReactToPrint } from "react-to-print";
import { useParams, useNavigate } from "react-router-dom";
import RefreshLoginModal from "../Modals/Auth";

import {
  Content,
  Container,
  Title,
  ContainerTypeAttendance,
  Priorities,
  ButtonInfo,
  ButtonTitle,
  ButtonDescription,
  ContainerPasswordsType,
} from "./styles";

import ToPrint from "../../components/ToPrint";
import Loading from "../../components/Loading";
import Api from "../../services/apiService";
import { toast } from "../../utils/toast";
import ButtonComponent from "../../components/Forms/Button";
import { ButtonContainer } from "../Admin/styles";

export default function Clinic() {
  const params = useParams();
  const navigate = useNavigate();

  const [activePrint, setActivePrint] = useState(false);
  const [key, setKey] = useState({});
  const [link, setLink] = useState("");
  const [type, setType] = useState("");
  const [unit, setUnit] = useState();
  const [priorities, setPriorities] = useState([]);
  const [displayModal, setDisplayModal] = useState("none");
  const [showLoginModal, setShowLoginModal] = useState("none");
  const [loading, setLoading] = useState(true);
  const [disableButtons, setDisableButtons] = useState(false)

  let componentRef = useRef(null);
  const handleAfterPrint = () => {
    toast(
      "success",
      "Imprimindo!!!",
      "Retire sua senha e aguarde na recepção "
    );
    setTimeout(() => {
      setDisableButtons(false)
    },4000)
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: handleAfterPrint
  });

  function closeModal() {
    setDisplayModal("none");
  }

  async function gerarSenha(prioridade = false, prioridade_id = undefined) {
    setDisableButtons(true)
    try {
      const payload = {};
      if (prioridade) payload.prioridade_id = prioridade_id;
      const { data } = await Api.post(
        `/senhas/gerar/${params.id}`,
        payload
      );
      setKey(data);
      setType(prioridade ? "Prioridade" : "Normal");
      setActivePrint(true);
      handlePrint();
      toast("info", "Aguarde!", "Estamos gerando sua senha");
      setActivePrint(false);
      if (prioridade_id) closeModal();
    } catch (error) {
      if(error?.response?.status === 401) setShowLoginModal("flex")
      setTimeout(() => {
        setDisableButtons(false)
      },4000)
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível gerar sua senha"
      );
    }
  }

  async function pegarUnidade() {
    try {
      const { data } = await Api.get(`/unidades/${params.id}`);
      setUnit(data);
    } catch (error) {
      if(error?.response?.status === 401) setShowLoginModal("flex")
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não foi possível buscar a unidade!"
      );
    }
  }

  async function loadPriorities() {
    try {
      const { data } = await Api.get("/prioridades", {
        params: { unidade_id: params.id, totem: true },
      });
      setPriorities(
        data.filter(
          (priority) => priority.nome_prioridade.toLowerCase() !== "e-senha"
        ) // Replace filter
      );
    } catch (error) {
      if(error?.response?.status === 401) setShowLoginModal("flex")
      toast(
        "error",
        "Erro",
        error?.response?.data?.message || "Não possível buscar as prioridades"
      );
    }
  }
  function getHref() {
    const Href = window.location.href.toString();
    setLink(Href.split("/totem"));
  }

  const groupedPriorities = useMemo(() => {
    const emphasis = [];
    const commons = [];
    priorities.forEach(p => {
      if (p.padrao) emphasis.push(p);
      else commons.push(p);
    });
    return { emphasis, commons };
  }, [priorities]);

  useEffect(() => {
    setLoading(true);
    Promise.all([pegarUnidade(), loadPriorities(), getHref()]).finally(() =>
      setLoading(false)
    );
  }, []);


  useEffect(() => {
    if (displayModal === 'none') return;

    const timeoutId = setTimeout(() => {
      setDisplayModal('none');
    }, 1000 * 15); // 15 segundos

    return () => {
      clearTimeout(timeoutId);
    };

  }, [displayModal])

  function closeWindowModal() {
    setShowLoginModal("none");
  }

  return (
    <Content>
      <RefreshLoginModal
        showLoginModal={showLoginModal}
        closeWindowModal={closeWindowModal}
        pegarUnidade={pegarUnidade}
      />
      <Header hideProfile />
      {displayModal === "flex" ? (
          <ButtonContainer style={{ alignSelf: 'flex-start' }}>
            <ButtonComponent text="<" HandleClick={() => setDisplayModal("none")} />
          </ButtonContainer>
       
      ) : null}
      {loading ? (
        <Loading setDisplay={loading} />
      ) : (
        <>
          <Container>
            {displayModal === "flex" ? (
              <>
                <Title>Selecione a prioridade</Title>
                <Priorities>
                  {groupedPriorities.commons.flatMap((p) => (
                    <ButtonPriorities isDisabled={disableButtons} key={p.id} HandleClick={() => { 
                      setDisplayModal("none")
                      gerarSenha(true, p.id)}
                    }>
                      <ButtonInfo>
                        <ButtonTitle>{p.nome_prioridade}</ButtonTitle>
                        <ButtonDescription>
                          {p.desc_prioridade}
                        </ButtonDescription>
                      </ButtonInfo>
                    </ButtonPriorities>
                  ))}
                </Priorities>
              </>
            ) : (
              <ContainerTypeAttendance>
                <Title>Selecione o tipo de atendimento</Title>
                <ContainerPasswordsType>
                <ButtonTypeAttendence
                  HandleClick={() => setDisplayModal("flex")}
                  isDisabled={disableButtons}
                  haveSubItem
                >
                  Atendimento Preferencial
                </ButtonTypeAttendence>
                {groupedPriorities.emphasis.flatMap(p => (  
                  <ButtonTypeAttendence isDisabled={disableButtons} HandleClick={() => gerarSenha(true, p.id)}
                  >
                    {p.nome_prioridade}
                  </ButtonTypeAttendence>
                ))}
                <ButtonTypeAttendence
                  HandleClick={() => navigate(`/validar-senha/${params.id}`)}
                  isDisabled={disableButtons}
                  haveSubItem
                >
                  Validar Senha
                </ButtonTypeAttendence>
                  </ContainerPasswordsType>
              </ContainerTypeAttendance>
            )}
          </Container>
          <ToPrint
            referency={componentRef}
            active={activePrint}
            setKey={key}
            link={link[0]}
            typeAttendance={type}
            unitName={unit?.unidade}
            unitId={unit?.id}
            priority={priorities.filter((p) => p.id === key?.prioridade_id)[0]}
          />
        </>
      )}
    </Content>
  );
}
