import React from "react";

import {
  Content,
  Button
} from './styles'

export default function ButtonPriorities({
  children,
  HandleClick,
  isDisabled
}){

  return (
    <Content>
          <Button disabled={isDisabled} onClick={() => HandleClick()}>
            {children}
          </Button>
    </Content>
  )
}