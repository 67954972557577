import React from "react";
import { ThemeProvider } from 'styled-components';
import { HashRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Global Styles from application
import GlobalStyles from './styles/globalStyles'

// Themes application
import BlueTheme from './styles/BlueTheme'

// Importing routes from application
import Router from "./routes";
import Loading from "./components/Loading";
import { IconContext } from "react-icons/lib";

function App() {
  const theme = BlueTheme;
  const {setDisplay} = useSelector((state) => state.loading)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <HashRouter>
        <Loading setDisplay={setDisplay} />
        <IconContext.Provider
          value={{ color: theme.colors.primary, size: 22 }}>
          <Router />      
        </IconContext.Provider>
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
