import { Link } from "react-router-dom";
import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 40px 40px;
  background-image: radial-gradient(#6d6b70 2px, transparent 2px);
`;

export const ContainerLogin = styled.div`
  width: 450px;
`;

export const FormLogin = styled.form`
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.login};
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.25rem 2.5rem 1.25rem;
`;

export const ContentLogo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 170px;
    margin-top: 8%;

    @media (max-width: 380px) {
      width: 80%;
      margin-top: 8%;
    }
  }
`;

export const ContentInput = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
  display: flex;
  flex-direction: column;
`;

export const ContentRememberPass = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.colors.whiteLogin};
  transition: 0.2s;
  font-weight: 500;
  > p {
    cursor: pointer;
    transition: 0.2s;
    :hover {
      text-shadow: 0px 0px 1px ${(props) => props.theme.colors.secondary};
    }
  }
`;
export const ContentVersion = styled.div`
  width: 100%;
  margin: 1.0rem 0.3rem 0rem 0.3rem;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colors.whiteLogin};
  font-weight: 500;
  font-size: 0.8rem;
`;

export const ContentButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    max-width: 60%;
    padding: 1rem 1.5rem;

    @media (max-width: 768px) {
      max-width: 80%;
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }
`;

export const Label = styled.p`
  font-size: 0.9rem;
  text-align: left;
  line-height: 1.5rem;
  font-weight: 600;
  width: 100%;
  color: #333333;
`;

export const Anchor = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
