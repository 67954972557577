import styled from "styled-components";

export const Content = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.secondary};
  background-size: 40px 40px;
  background-image: radial-gradient(#6d6b70 2px, transparent 2px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerLogin = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  padding: 15px 10px;
  border-radius: 10px;
  /* align-items: center; */
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  background-color: green;
`;

export const ContentLogo = styled.img`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  align-self: center;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const ContentRememberPass = styled.div`
  display: flex;
  justify-content: flex-end;
  color:${props => props.theme.colors.whiteLogin};
  transition: 0.2s;
  cursor: pointer;
  font-weight: 500;
`;

export const ContentButton = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Warning = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  width: 400px;
  align-self: center;
  text-align: center;
  margin: 30px;
`;