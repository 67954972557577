import styled, { css } from "styled-components";

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.secondary};
`;

export const Logo = styled.img`
  height: 35px;
  margin: ${({ hasMargin }) => hasMargin ? '27.5px 0px' : '0px'};
  margin-left: 15px;
`;

export const ImageProfile = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${ props => props.theme.colors.gray };
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${ props => props.theme.colors.white };
`;

export const Unit = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: row;
  cursor: ${({ hideProfile }) => hideProfile ? "default" : "pointer"};
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 700;
  color: ${props => props.isActive ? props.theme.colors.white : props.theme.colors.white};
  padding: 20px 15px;

  :hover{
    ${({ theme, hideProfile }) => hideProfile
      ? css``
      : css`
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
      `
    }
    > span {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

export const HelloText = styled.span`
  color: ${({ theme, isActive }) => isActive ? theme.colors.secondary : theme.colors.primary};
  margin-left: 15px;

  :hover {
    color: ${({ theme }) => theme.colors.secondary}
  }
`;

export const DropMenu = styled.div`
  width: 100%;
  height: 100px;
  background-color: ${ props => props.theme.colors.primary };
  color: ${ props => props.theme.colors.white };
  position: absolute;
  top: 100%;
  left: 0px;
  border-radius: 0 0 10px 10px;
  display: ${ props => props.setDisplay ? 'flex' : 'none' };
  transition: 0.2s;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 5;
`;

export const DropButton = styled.div`
  width: 95%;
  height: 40px;
  padding: 0 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  font-weight: 600;

  :hover{
    background-color: ${ props => props.theme.colors.white };
    color: ${ props => props.theme.colors.secondary } ;

    > svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const IconMenu = styled.div`
  width: 40px;
  height: 40px;
  display: none;
  background-color: red;
  @media (max-width: 380px){
    display: flex;
  }
`