import { useState } from 'react';
import { useTheme } from 'styled-components';
import { MdClose, MdSearch } from 'react-icons/md';
import { Container, Button, Input } from './styles';

export function Search({itens = [], handleSearch}) {
  const theme = useTheme();
  const [search, setSearch] = useState();

  function clearSearch() {
    setSearch("");
    handleSearch("");
  }

  return (
    <Container>
      <Input
        placeholder={`Pesquisar por ${itens.map(i => i.label).join(', ')}`}
        onChange={({ target: { value }}) => setSearch(value)}
        value={search}
      />
      <Button onClick={search ? clearSearch : undefined}>
        <MdClose color={search? theme.colors.warning : '#f5f5f5'} />
      </Button>
      <Button onClick={() => handleSearch(search)}>
        <MdSearch />
      </Button>
    </Container>
  );
}