import React from "react";

import { Content } from "./styles";

export default function Key({ children, isSelected, handleClick }) {
  return (
    <Content isActive={isSelected} onClick={() => handleClick()}>
      {children}
    </Content>
  );
}
